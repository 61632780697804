function getWantTimestamp(props) {
  //当前时间
  let getLosAngelesDateAry = getLosAngelesDate()
  const losAngelesDate = new Date(getLosAngelesDateAry[0])
  const currentTimeZoneName = getLosAngelesDateAry[1]

  switch (props) {
    // 当天开始时间
    case 'dayStart': {
      let dateStr = `${losAngelesDate.getFullYear()}/${losAngelesDate.getMonth() + 1}/${losAngelesDate.getDate()} ${0}:${0}:${0} ${currentTimeZoneName}`
      return new Date(dateStr).getTime();
    }
    // 当天结束时间
    case 'dayEnd': {
      let dateStr = `${losAngelesDate.getFullYear()}/${losAngelesDate.getMonth() + 1}/${losAngelesDate.getDate()} ${23}:${59}:${59} ${currentTimeZoneName}`
      return new Date(dateStr).getTime() + 1000;
    }

    // 本周星期一零点的时间戳
    case "thisMondayZero": {
      var firstDayDate = losAngelesDate;
      // 本周一的日期 
  
      firstDayDate.setDate(firstDayDate.getDate() - (firstDayDate.getDay() || 7) + 1);
      let dateStr = `${firstDayDate.getFullYear()}/${firstDayDate.getMonth() + 1}/${firstDayDate.getDate()} ${0}:${0}:${0} ${currentTimeZoneName}`
      return new Date(dateStr).getTime();
    }

    // 本周结束的时间戳
    case "thisWeekEnd": {
      var endDayDate = losAngelesDate
      var firstDayDate = losAngelesDate
      firstDayDate.setDate(firstDayDate.getDate() - (firstDayDate.getDay() || 7) + 1);
      endDayDate.setDate(firstDayDate.getDate() + 6);
      let dateStr = `${endDayDate.getFullYear()}/${endDayDate.getMonth() + 1}/${endDayDate.getDate()} ${23}:${59}:${59} ${currentTimeZoneName}`
      return new Date(dateStr).getTime() + 1000;
    }

    // 本月开始的时间戳
    case "thisMonthBeginZero": {
      let dateStr = `${losAngelesDate.getFullYear()}/${losAngelesDate.getMonth() + 1}/${1} ${0}:${0}:${0} ${currentTimeZoneName}`
      return new Date(dateStr).getTime()
    }

    // 本月结束的时间戳
    case "thisMonthEndTime": {
      let monthEndDate = new Date(losAngelesDate.getFullYear(), losAngelesDate.getMonth() + 1, 0);
      let dateStr = `${monthEndDate.getFullYear()}/${monthEndDate.getMonth() + 1}/${monthEndDate.getDate()} ${23}:${59}:${59} ${currentTimeZoneName}`
      return new Date(dateStr).getTime() + 1000;
    }

    default: {
      console.log("时间参数错误");
      return 0;
    }
  }
}

function getLosAngelesDate() {
  let dateMap = getLosAngelesTime(false)
  let currentYear;
  let currentMonth;
  let currentDay;
  let currentHour;
  let currentMinute;
  let currentSecond;
  let currentTimeZoneName;

  dateMap.forEach((data) => {
    if (data.type == 'year') {
      currentYear = Number(data.value)
    }

    if (data.type == 'month') {
      currentMonth = Number(data.value)
    }

    if (data.type == 'day') {
      currentDay = Number(data.value)
    }

    if (data.type == 'hour') {
      currentHour = Number(data.value)
    }

    if (data.type == 'minute') {
      currentMinute = Number(data.value)
    }

    if (data.type == 'second') {
      currentSecond = Number(data.value)
    }
    if (data.type == 'timeZoneName') {
      currentTimeZoneName = data.value
    }
  })
  //2019-04-21 21:00:00 EDT
  // let losAngeData = new Date(currentYear, currentMonth - 1, currentDay, currentHour > 23 ? 0 : currentHour, currentMinute, currentSecond)
  let dateStr = `${currentYear}/${currentMonth}/${currentDay} ${currentHour > 23 ? 0 : currentHour < 10 ? '0' + currentHour : currentHour}:${currentMinute < 10 ? '0' + currentMinute : currentMinute}:${currentSecond < 10 ? '0' + currentSecond : currentSecond}`
  // console.log(dateStr)
  // let losAngeData = new Date(dateStr)
  // console.log(losAngeData)
  return [dateStr, currentTimeZoneName]
}

function getLosAngelesTime(needHour12) {
  var date = Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: needHour12 ? true : false,
    timeZoneName: "short",
    timeZone: 'America/Los_Angeles'
  }).formatToParts(new Date())
  return date
}

const timeUtils = {
  getWantTimestamp,
  getLosAngelesTime
};

export default timeUtils;