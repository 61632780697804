import axios from 'axios';

export function fetch(url, query = {}, config) {

    let axiosConfig = {
        url: url,
        // timeout: 5000,
        withCredentials: true,
        headers: { 'Accept': '*/*' }
    }

    if (config.method == 'get') {
        axiosConfig['params'] = query;
    } else {
        axiosConfig['method'] = config.method;
        axiosConfig['data'] = query;

    }

    axiosConfig.headers['Content-Type'] = 'application/json; charset=utf-8'

    return new Promise((resolve, reject) => {
        try {
            axios(axiosConfig).then(res => {
                if (res.status == 200) {
                    resolve(res.data);
                } else {
                    reject(res.data);
                }

            }).catch((error) => {
                reject(error);
            })
        } catch (e) {
            reject(e)
        }

    })
}