import io from 'socket.io-client';
import tokenTxt from '../../public/xiaocuntoken.txt';

const MUNCHO_WEBSOCKET_URL = process.env.NODE_ENV === 'production' ? 'https://ws.muncho.io/' : 'https://test-muncho-admin.muncho.io/'
const XIAOCUN_WEBSOCKET_URL = process.env.NODE_ENV === 'production' ? 'wss://xiaocun.imgup.cn/ws/' : 'wss://pears.xiaocundayou.com/ws/'
const KEYOU_WEBSOCKET_URL = process.env.NODE_ENV === 'production' ? 'https://keyou.xiaocundayou.com/' : 'https://keyou.xiaocundayou.com/'

const munchoSocketIo = io(MUNCHO_WEBSOCKET_URL, { query: { from: 'data-views' }, transports: ['websocket'] });

const keyouSocketIo = io(KEYOU_WEBSOCKET_URL, { transports: ['websocket'] });

// const xiaocunWebSocket = new WebSocket('wss://pears.xiaocundayou.com/ws/business&token=' + token)

// async function getXiaocunWebSocket() {
//    let txt = await fetch(tokenTxt)
//    return new WebSocket('wss://pears.xiaocundayou.com/ws/business&token=' + token)
// }

// function getXiaocunWebSocket(token) {
//     return new WebSocket('wss://pears.xiaocundayou.com/ws/business&token=' + token)
// }


function initXiaocunWebsocket() {
    return new Promise((resolve, reject) => {
        fetch(tokenTxt)
            .then(r => r.text())
            .then(token => {
                const socket = new WebSocket(`${XIAOCUN_WEBSOCKET_URL}business&token=`+ token);
                resolve(socket)
            }).catch((error) => {
                reject(error)
                console.log('token读取失败')
            });

    })
}

const socket = {
    initXiaocunWebsocket,
    munchoSocketIo,
    keyouSocketIo
};
export default socket;
