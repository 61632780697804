import { fetch } from './axiosUtil';

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://dashboard.xiaocundayou.com/apis': 'https://dashboard.xiaocundayou.net/apis'
const MUNCHO_DATA_URL = `${BASE_URL}/muncho`
const XIAOCUN_DATA_URL = `${BASE_URL}/xiaocun`
const KEYOU_DATA_URL = `${BASE_URL}/keyou`

function getMunchoData(startTime, endTime, itemsData) {
    return fetch(`${MUNCHO_DATA_URL}/public/dashboard?endTimeStamp=${endTime}&startTimeStamp=${startTime}`, { 'items': JSON.stringify(itemsData) }, {
        method: 'get'
    });
    // return fetch('http://192.168.0.106:7001/businesses?longitude=-121.7405167&latitude=38.5449065&range=40&filterMode[]=0&filterMode[]=0&pageNumber=0&pageCapacity=10', {}, {
    //     method: 'get'
    // });
}

function getXiaocunData(startTime, endTime, itemsData) {
    return fetch(`${XIAOCUN_DATA_URL}/dashboard/index?endTimeStamp=${endTime}&startTimeStamp=${startTime}`, { 'items': JSON.stringify(itemsData) }, {
        method: 'get'
    });

}

function getKeyouData(startTime, endTime, itemsData) {
    return fetch(`${KEYOU_DATA_URL}/manager/home/usersStatistics?endTimeStamp=${endTime}&startTimeStamp=${startTime}`, { 'items': JSON.stringify(itemsData) }, {
        method: 'get'
    });
   
}

function login(phone, password) {
    return fetch(`${BASE_URL}/login`, { 'username': phone, 'password': password}, {
        method: 'post'
    });
}

function logout() {
    return fetch(`${BASE_URL}/logout`, {}, {
        method: 'get'
    });
}



const service = {
    getMunchoData,
    getXiaocunData,
    getKeyouData,
    login,
    logout
};

export default service;