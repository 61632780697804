import React, { Component, PropTypes } from 'react';
import localStorageUtils from '../scripts/util/localStorageUtils'

class App extends Component {

  componentDidMount() {
    if (localStorageUtils.getLocalUserId()) {
      this.props.history.replace('home')
    } else {
      this.props.history.replace('login')
    }

  }

  render() {
    return (
      <div></div>
    )
  }
}

export default App;