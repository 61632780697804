function saveUserId(userId) {
    localStorage.setItem('dataViewsUserId', userId)
}

function clear() {
    localStorage.removeItem('dataViewsUserId')
}

function getLocalUserId() {
   return localStorage.getItem('dataViewsUserId')
}


const localStorageUtils = {
    saveUserId,
    clear,
    getLocalUserId
};

export default localStorageUtils;