import React, { Component, PropTypes } from 'react';
import './login.css';
import localStorageUtils from '../scripts/util/localStorageUtils'
import service from '../scripts/service/service'
import ReactLoading from "react-loading";


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false
        }
    }

    login() {
        this.setState({
            showLoading: true
        })
        service.login(this.phone.value, this.password.value).then(res => {
            this.setState({
                showLoading: false
            })
            localStorageUtils.saveUserId(res.user.id)
            this.props.history.replace('home')
        }).catch(err => {
            this.setState({
                showLoading: false
            })
            alert(err)
        })
    }

    render() {
        return (
            <div className='app'>
                <div className='login-content'>
                    <div className='dialog'>
                        <img src={'./xiaocun_logo@2x.png'} className='login-img' />
                        <div style={{ marginBottom: '11px' }}><span className='login-title'>Welcom to Xiaocun</span></div>
                        <div className='login-input-bg'>
                            <input ref={(phone) => { this.phone = phone }} placeholder='Phone Number' className='login-input' />
                        </div>

                        <div className='login-input-bg'>
                            <input ref={(password) => { this.password = password }} type='password' placeholder='Password' className='login-input' />
                        </div>

                        <div onClick={() => {
                            this.login()
                        }} className='login-button'>
                            <span style={{ color: '#fff', fontSize: '14px' }}>Log in</span>
                        </div>
                    </div>
                </div>
                {this.state.showLoading ? <div className='loadingBg'>
                    <ReactLoading type={'spokes'} color="#fff" />
                </div> : null}
            </div>
        )
    }
}

export default Login;