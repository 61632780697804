import React from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import Login from './login';
import App from './App';
import Home from './home';
import { createHashHistory } from 'history';
const hashHistory = createHashHistory();

const BasicRoute = () => (
    <HashRouter history={hashHistory}>
        <Switch>
            <Route exact path="/" component={App}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/home" component={Home}/>
        </Switch>
    </HashRouter>
);

export default BasicRoute;