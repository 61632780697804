import logo from './logo.svg';
import React, { Component, PropTypes } from 'react';
import './App.css';
import service from '../scripts/service/service'
import timeUtils from '../scripts/util/timeUtil';
import $, { data } from 'jquery';
import { CountUp } from 'countup.js';
import webSocketUtils from '../scripts/service/webSocketUtils';
import ReactLoading from "react-loading";
import localStorageUtils from '../scripts/util/localStorageUtils'
import { createHashHistory } from 'history';

const hashHistory = createHashHistory();
var xiaocunWebsocket;
var pingInterval;
var reloadInterval;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ordersSwitchCheckWeek: true,
      popularSwitchCheckWeek: true,
      addedSwitchCheckWeek: true,

      queryStartTime: 0,
      queryEndTime: 0,

      xiaocunPostedData: {
        newPosts: 0,
        newUsers: 0,
        peopleChats: 0,
        peopleLeaveMessages: 0,
        peoplePosted: 0,
        removePosts: 0,
        postTopCategories: []
      },
      keyouUserData: {
        addGroup: 0,
        register: 0,
        shareregister: 0,
        topuniversities: []
      },
      xiaocunOrderTopCity: [],
      xiaocunFirstTimeOrderUsers: {},
      xiaocunInvalidOrders: {},
      xiaocunCurrentCompletedOrders: {},
      xiaocunPreviousCompletedOrders: {},

      munchoOrderTopCity: [],
      munchoFirstTimeOrderUsers: {},
      munchoInvalidOrders: {},
      munchoCurrentCompletedOrders: {},
      munchoPreviousCompletedOrders: {},

      todayNumberOfOrders: 0,
      todayNewPosts: 0,
      todayXiaocunAmount: 0,
      todayMunchoAmount: 0,
      todayXiaocunOrderNum: 0,
      todayMunchoOrderNum: 0,
      keyouActiveUserNum: 0,
      showLoading: false
    }
  }

  showLoadingView() {
    this.setState({
      showLoading: true
    })
  }

  hideLoadingView() {
    this.setState({
      showLoading: false
    })
  }

  logout() {
    this.setState({
      showLoading: true
    })
    service.logout().then(() => {
      this.setState({
        showLoading: false
      })
      localStorageUtils.clear()
      hashHistory.replace('login')
      clearInterval(pingInterval)
      clearInterval(reloadInterval)
    }).catch(() => {
      this.setState({
        showLoading: false
      })
      clearInterval(pingInterval)
      clearInterval(reloadInterval)
      localStorageUtils.clear()
      hashHistory.replace('login')
    })

  }

  netWorkError(error) {
    if (error.response && Object.keys(error.response).length > 0 && error.response.status == 401) {
      this.logout()

      if (webSocketUtils.munchoSocketIo) {
        webSocketUtils.munchoSocketIo.disconnect()
      }

      if (webSocketUtils.keyouSocketIo) {
        webSocketUtils.keyouSocketIo.disconnect()
      }

      if (xiaocunWebsocket) {
        xiaocunWebsocket.close()
      }
    }
  }

  stopWatch() {
    setInterval(() => {
      if (this.time == undefined) { return }
      let timeAry = timeUtils.getLosAngelesTime(true)
      let currentYear;
      let currentMonth;
      let currentWeekDay;
      let currentDay;
      let currentHour;
      let currentMinute;
      let currentSecond;
      let dayPeriod;
      timeAry.forEach((data) => {
        if (data.type == 'year') {
          currentYear = Number(data.value)
        }

        if (data.type == 'month') {
          currentMonth = Number(data.value)
        }

        if (data.type == 'day') {
          currentDay = Number(data.value)
        }

        if (data.type == 'hour') {
          currentHour = Number(data.value)
        }

        if (data.type == 'minute') {
          currentMinute = Number(data.value)
        }

        if (data.type == 'second') {
          currentSecond = Number(data.value)
        }

        if (data.type == 'weekday') {
          currentWeekDay = data.value
        }

        if (data.type == 'dayPeriod') {
          dayPeriod = data.value
        }
      })

      this.time.innerHTML = (currentHour < 10 ? "0" + currentHour : currentHour) + ":" + (currentMinute < 10 ? "0" + currentMinute : currentMinute) + ":" + (currentSecond < 10 ? "0" + currentSecond : currentSecond)
      this.dayPeriod.innerHTML = dayPeriod
      this.weekDay.innerHTML = currentWeekDay
      this.year.innerHTML = currentMonth + '/' + currentDay + '/' + currentYear
    }, 1000)

  }

  isJsonString(str) {
    try {
      if (typeof JSON.parse(str) == 'object') {
        return true;
      }
    } catch (e) {
      // console.log(e);
    }
    return false;
  }

  

  async initWebSocket() {
    xiaocunWebsocket = await webSocketUtils.initXiaocunWebsocket()

    xiaocunWebsocket.addEventListener('open', event => {
      console.log('小村websocket连接成功')
      xiaocunWebsocket.send('ping');
      pingInterval = setInterval(() => {
        xiaocunWebsocket.send('ping');
      }, 30000)
      // setInterval(() => {
      //   console.log('小村websocket状态：'+xiaocunWebsocket.readyState())
      //   xiaocunWebsocket.send('ping');
      // }, 30000)
    });

    xiaocunWebsocket.addEventListener('message', event => {
      console.log('Message from server ' + event.data);

      if (this.isJsonString(event.data)) {

        let res = JSON.parse(event.data);
        if (res.message) {
          if (res.message && (res.message.type == 3 || res.message.type == 4)) {
            if (res.message.type == 3) {
              this.newPosts.play()
              this.requestTodayData('newPosted')
            }
            if (res.message.type == 4) {
              this.newOrder.play()
              this.requestTodayData('xiaocunNewOrder')
            }
          }
        }
      }

    });

    webSocketUtils.munchoSocketIo.on('dashboard_order_completed', msg => {
      this.newOrder.play()
      this.requestTodayData('munchoNewOrder')
    });

    webSocketUtils.keyouSocketIo.on('display', msg => {
      if (msg == 'online') {
        if (this.activeUsersAudio) {
          this.activeUsersAudio.play()
        }
        this.requestTodayData('keyouNewActivity')
      }
    })
  }


  showtodayTotalAmount(amount) {
    if (this.todayTotalAmount) {
      const options = {
        startVal: this.todayTotalAmount.innerHTML,
        decimalPlaces: 2,
        duration: 2,
      };
      let demo = new CountUp(this.todayTotalAmount, amount, options);
      if (!demo.error) {
        demo.start();
      } else {
        console.error(demo.error);
      }
    }
  }

  async setOrdersSwitchCheck(checkWeek) {

    if (this.state.ordersSwitchCheckWeek == checkWeek) {
      return
    }
    this.setState({
      ordersSwitchCheckWeek: checkWeek
    })
    this.showLoadingView()
    let params = ["newUsers", "currentCompletedOrders", "previousCompletedOrders", "invalidOrders", "firstTimeOrderUsers", "orderTopCity"]
    let xiaocunRes = {}
    let munchoRes = {}
    if (checkWeek) {
      xiaocunRes = await service.getXiaocunData(timeUtils.getWantTimestamp('thisMondayZero'), timeUtils.getWantTimestamp('thisWeekEnd'), params).catch(err => {
        this.netWorkError(err)
        this.hideLoadingView()
      });
      if (!xiaocunRes) {
        xiaocunRes = {}
      }

      munchoRes = await service.getMunchoData(timeUtils.getWantTimestamp('thisMondayZero'), timeUtils.getWantTimestamp('thisWeekEnd'), params).catch(err => {
        console.log(err)
        this.netWorkError(err)
        this.hideLoadingView()
      });
      if (!munchoRes) {
        munchoRes = {}
      }
    } else {
      xiaocunRes = await service.getXiaocunData(timeUtils.getWantTimestamp('thisMonthBeginZero'), timeUtils.getWantTimestamp('thisMonthEndTime'), params).catch(err => {
        this.netWorkError(err)
        this.hideLoadingView()
      });
      if (!xiaocunRes) {
        xiaocunRes = {}
      }
      munchoRes = await service.getMunchoData(timeUtils.getWantTimestamp('thisMonthBeginZero'), timeUtils.getWantTimestamp('thisMonthEndTime'), params).catch(err => {
        this.netWorkError(err)
        this.hideLoadingView()
      });
      if (!munchoRes) {
        munchoRes = {}
      }
    }
    this.hideLoadingView()
    this.setState({
      xiaocunOrderTopCity: xiaocunRes.orderTopCity,
      xiaocunFirstTimeOrderUsers: xiaocunRes.firstTimeOrderUsers,
      xiaocunInvalidOrders: xiaocunRes.invalidOrders,
      xiaocunCurrentCompletedOrders: xiaocunRes.currentCompletedOrders,
      xiaocunPreviousCompletedOrders: xiaocunRes.previousCompletedOrders,

      munchoOrderTopCity: munchoRes.orderTopCity,
      munchoFirstTimeOrderUsers: munchoRes.firstTimeOrderUsers,
      munchoInvalidOrders: munchoRes.invalidOrders,
      munchoCurrentCompletedOrders: munchoRes.currentCompletedOrders,
      munchoPreviousCompletedOrders: munchoRes.previousCompletedOrders
    })

  }

  async setPopularSwitchCheck(checkWeek) {
    if (this.state.popularSwitchCheckWeek == checkWeek) {
      return
    }
    this.setState({
      popularSwitchCheckWeek: checkWeek
    })
    this.showLoadingView()
    let params = ["newUsers", "peoplePosted", "peopleLeaveMessages", "peopleChats", "newPosts", "removePosts", "postTopCategories"]
    let res = {}
    if (checkWeek) {
      res = await service.getXiaocunData(timeUtils.getWantTimestamp('thisMondayZero'), timeUtils.getWantTimestamp('thisWeekEnd'), params).catch(err => {
        this.netWorkError(err)
        this.hideLoadingView()
      });
    } else {
      res = await service.getXiaocunData(timeUtils.getWantTimestamp('thisMonthBeginZero'), timeUtils.getWantTimestamp('thisMonthEndTime'), params).catch(err => {
        this.netWorkError(err)
        this.hideLoadingView()
      });
    }
    this.hideLoadingView()
    this.setState({
      xiaocunPostedData: res
    })
  }

  async setAddedSwitchCheck(checkWeek) {
    if (this.state.addedSwitchCheckWeek == checkWeek) {
      return
    }
    this.showLoadingView()
    this.setState({
      addedSwitchCheckWeek: checkWeek
    })
    let params = ["register", "addGroup", "shareregister", "topuniversities"]
    let res = {}
    if (checkWeek) {
      res = await service.getKeyouData(timeUtils.getWantTimestamp('thisMondayZero'), timeUtils.getWantTimestamp('thisWeekEnd'), params).catch(err => {
        this.netWorkError(err)
        this.hideLoadingView()
      });
    } else {
      res = await service.getKeyouData(timeUtils.getWantTimestamp('thisMonthBeginZero'), timeUtils.getWantTimestamp('thisMonthEndTime'), params).catch(err => {
        this.netWorkError(err)
        this.hideLoadingView()
      });
    }
    this.hideLoadingView()
    this.setState({
      keyouUserData: res
    })
  }

  async requestTodayData(type) {
    let todayQueryStartTime = timeUtils.getWantTimestamp('dayStart')
    let todayQueryEndTime = timeUtils.getWantTimestamp('dayEnd')
    switch (type) {
      case 'keyouNewActivity': {
        let keyouTodayRes = await service.getKeyouData(todayQueryStartTime, todayQueryEndTime, ["online"]).catch(err => {
          this.netWorkError(err)
        });
        if (!keyouTodayRes) {
          keyouTodayRes = {}
        }
        this.setState({
          keyouActiveUserNum: keyouTodayRes.online
        })
        break
      }
      case 'munchoNewOrder': {
        let munchoTodayRes = await service.getMunchoData(todayQueryStartTime, todayQueryEndTime, ['currentCompletedOrders']).catch(err => {
          this.netWorkError(err)
          console.log(err)
        });
        this.state.todayMunchoAmount = munchoTodayRes.currentCompletedOrders.transactionAmount
        // let amount = Number(((this.state.todayMunchoAmount + this.state.todayXiaocunAmount) / 1000).toFixed(2))
        let amount = Number(((this.state.todayMunchoAmount + this.state.todayXiaocunAmount)).toFixed(2))
        if (amount > 1000) {
          this.thousand.innerHTML = 'k'
          amount = (amount / 1000).toFixed(2)
        }
        this.state.todayMunchoOrderNum = munchoTodayRes.currentCompletedOrders.deliveryCount + munchoTodayRes.currentCompletedOrders.pickUpCount + munchoTodayRes.currentCompletedOrders.dineinCount

        this.showtodayTotalAmount(amount)
        this.setState({
          todayNumberOfOrders: this.state.todayXiaocunOrderNum + this.state.todayMunchoOrderNum
        })
        break
      }
      case 'xiaocunNewOrder': {
        let xiaocunTodayRes = await service.getXiaocunData(todayQueryStartTime, todayQueryEndTime, ['currentCompletedOrders']).catch(err => {
          this.netWorkError(err)
          console.log(err)
        });
        this.state.todayXiaocunAmount = xiaocunTodayRes.currentCompletedOrders.transactionAmount
        // let amount = Number(((this.state.todayMunchoAmount + this.state.todayXiaocunAmount) / 1000).toFixed(2))
        let amount = Number(((this.state.todayMunchoAmount + this.state.todayXiaocunAmount)).toFixed(2))
        if (amount > 1000) {
          this.thousand.innerHTML = 'k'
          amount = (amount / 1000).toFixed(2)
        }

        this.showtodayTotalAmount(amount)

        this.state.todayXiaocunOrderNum = xiaocunTodayRes.currentCompletedOrders.deliveryCount + xiaocunTodayRes.currentCompletedOrders.pickUpCount


        this.showtodayTotalAmount(amount)
        this.setState({
          todayNumberOfOrders: this.state.todayXiaocunOrderNum + this.state.todayMunchoOrderNum
        })
        break
      }
      case 'newPosted': {
        let xiaocunTodayRes = await service.getXiaocunData(todayQueryStartTime, todayQueryEndTime, ['newPosts']).catch(err => {
          this.netWorkError(err)
          console.log(err)
        });
        this.setState({
          todayNewPosts: xiaocunTodayRes.newPosts
        })
        break
      }
    }
  }

  async getData() {

    let queryStartTime = timeUtils.getWantTimestamp('thisMondayZero')
    let queryEndTime = timeUtils.getWantTimestamp('thisWeekEnd')

    let todayQueryStartTime = timeUtils.getWantTimestamp('dayStart')
    let todayQueryEndTime = timeUtils.getWantTimestamp('dayEnd')

    let munchoRes = await service.getMunchoData(queryStartTime, queryEndTime, ["newUsers", "peoplePosted", "peopleLeaveMessages", "peopleChats", "newPosts", "removePosts", "postTopCategories", "currentCompletedOrders", "previousCompletedOrders", "invalidOrders", "firstTimeOrderUsers", "orderTopCity"]).catch(err => {
      this.netWorkError(err)
      console.log(err)
    }).catch(e => {
      this.netWorkError(e)
      console.log('networkError4')
      console.log(e)
    });
    if (!munchoRes) {
      munchoRes = {}
    }
    let xiaocunRes = await service.getXiaocunData(queryStartTime, queryEndTime, ["newUsers", "peoplePosted", "peopleLeaveMessages", "peopleChats", "newPosts", "removePosts", "postTopCategories", "currentCompletedOrders", "previousCompletedOrders", "invalidOrders", "firstTimeOrderUsers", "orderTopCity"]).catch(err => {
      this.netWorkError(err)
      console.log(err)
    });
    if (!xiaocunRes) {
      xiaocunRes = {}
    }
    let keyouRes = await service.getKeyouData(queryStartTime, queryEndTime, ["register", "addGroup", "shareregister", "topuniversities"]).catch(err => {
      this.netWorkError(err)
      console.log(err)
    });
    if (!keyouRes) {
      keyouRes = {}
    }
    let munchoTodayRes = await service.getMunchoData(todayQueryStartTime, todayQueryEndTime, ['currentCompletedOrders']).catch(err => {
      this.netWorkError(err)
      console.log(err)
    });
    if (!munchoTodayRes) {
      munchoTodayRes = {}
    }
    let xiaocunTodayRes = await service.getXiaocunData(todayQueryStartTime, todayQueryEndTime, ['currentCompletedOrders', 'newPosts']).catch(err => {
      this.netWorkError(err)
      console.log(err)
    });
    if (!xiaocunTodayRes) {
      xiaocunTodayRes = {}
    }

    let keyouTodayRes = await service.getKeyouData(todayQueryStartTime, todayQueryEndTime, ["online"]).catch(err => {
      this.netWorkError(err)
      console.log(err)
    });
    if (!keyouTodayRes) {
      keyouTodayRes = {}
    }


    if (munchoTodayRes && Object.keys(munchoTodayRes).length > 0) {
      this.state.todayMunchoAmount = munchoTodayRes.currentCompletedOrders.transactionAmount
      this.state.todayMunchoOrderNum = munchoTodayRes.currentCompletedOrders.deliveryCount + munchoTodayRes.currentCompletedOrders.pickUpCount + munchoTodayRes.currentCompletedOrders.dineinCount
    }

    if (xiaocunTodayRes && Object.keys(xiaocunTodayRes).length > 0) {
      this.state.todayXiaocunAmount = xiaocunTodayRes.currentCompletedOrders.transactionAmount
      this.state.todayXiaocunOrderNum = xiaocunTodayRes.currentCompletedOrders.deliveryCount + xiaocunTodayRes.currentCompletedOrders.pickUpCount
    }

    // let amount = Number(((this.state.todayMunchoAmount + this.state.todayXiaocunAmount) / 1000).toFixed(2))
    let amount = Number(((this.state.todayMunchoAmount + this.state.todayXiaocunAmount)).toFixed(2))
    if (amount > 1000) {
      this.thousand.innerHTML = 'k'
      amount = (amount / 1000).toFixed(2)
    }

    this.showtodayTotalAmount(amount)

    this.setState({
      xiaocunOrderTopCity: xiaocunRes.orderTopCity,
      xiaocunFirstTimeOrderUsers: xiaocunRes.firstTimeOrderUsers,
      xiaocunInvalidOrders: xiaocunRes.invalidOrders,
      xiaocunCurrentCompletedOrders: xiaocunRes.currentCompletedOrders,
      xiaocunPreviousCompletedOrders: xiaocunRes.previousCompletedOrders,
      xiaocunPostedData: this.matchingElement(this.state.xiaocunPostedData, xiaocunRes),

      munchoOrderTopCity: munchoRes.orderTopCity,
      munchoFirstTimeOrderUsers: munchoRes.firstTimeOrderUsers,
      munchoInvalidOrders: munchoRes.invalidOrders,
      munchoCurrentCompletedOrders: munchoRes.currentCompletedOrders,
      munchoPreviousCompletedOrders: munchoRes.previousCompletedOrders,

      keyouUserData: keyouRes,

      todayNewPosts: xiaocunTodayRes.newPosts,
      todayNumberOfOrders: (this.state.todayXiaocunOrderNum + this.state.todayMunchoOrderNum),
      keyouActiveUserNum: keyouTodayRes.online
    })
  }

  mergeTopCityData(xiaocunData, munchoData) {
    let longAry = []
    let shortAry = []
    let longAryName = "xiaocun"
    if (xiaocunData.length > munchoData.length) {
      longAry = xiaocunData
      shortAry = munchoData
      longAryName = 'xiaocun'
    } else {
      longAry = munchoData
      shortAry = xiaocunData
      longAryName = 'muncho'
    }
    let topCityAry = new Array(longAry.length)
    longAry.forEach((data, index) => {
      let totalCount = data.count
      let xiaocunOrderCount = 0
      let munchoOrderCount = 0
      if (longAryName == 'xiaocun') {
        xiaocunOrderCount = data.count
      } else {
        munchoOrderCount = data.count
      }

      let region = data.area
      for (let i = 0; i < shortAry.length; i++) {
        let info = shortAry[i]
        if (data.country == info.country && data.state == info.state && data.area == info.area) {
          if (longAryName == 'xiaocun') {
            munchoOrderCount = info.count
          } else {
            xiaocunOrderCount = info.count
          }
          totalCount += info.count
          break
        }
      }
      topCityAry[index] = {
        totalCount: totalCount,
        xiaocunOrderCount: xiaocunOrderCount,
        munchoOrderCount: munchoOrderCount,
        region: region
      }
    })

    return topCityAry.sort((a, b) => {
      if (a.totalCount > b.totalCount) {
        return -1
      }
      else if (a.totalCount < b.totalCount) {
        return 1
      } else {
        return 0
      }
    })

  }

  matchingElement(needMatchingElement, originalElement) {

    Object.keys(needMatchingElement).forEach((key) => {
      if (originalElement.hasOwnProperty(key)) {
        needMatchingElement[key] = originalElement[key]
      }
    })
    return needMatchingElement
  }

  calculateContrast(current, pre) {
    if (current <= 0 && pre <= 0) {
      return 0
    }
    if (pre <= 0) {
      return -100
    }
    return Number(((pre - current) / pre * 100).toFixed(2))
  }

  componentDidMount() {
    this.getData()
    this.stopWatch()
    this.initWebSocket()
    reloadInterval = setInterval(() => {
      window.location.reload()
    }, 60 * 60 * 1000)
  }

  //计算对象数组中某个属性合计
  countTotal(arr, keyName) {

    let total = 0;
    arr.forEach(element => {
      total += element[keyName]
    });

    return total;
  }

  render() {
    const {
      ordersSwitchCheckWeek,
      popularSwitchCheckWeek,
      addedSwitchCheckWeek,
      xiaocunPostedData,
      xiaocunOrderTopCity,
      xiaocunFirstTimeOrderUsers,
      xiaocunInvalidOrders,
      xiaocunCurrentCompletedOrders,
      xiaocunPreviousCompletedOrders,
      munchoOrderTopCity,
      munchoFirstTimeOrderUsers,
      munchoInvalidOrders,
      munchoCurrentCompletedOrders,
      munchoPreviousCompletedOrders,
      todayNewPosts,
      todayNumberOfOrders,
      keyouUserData,
      keyouActiveUserNum
    } = this.state

    // const moneyAudioSrc = './money.wav'
    const activeUsersSrc = './activeUsersSrc.wav'
    const newOrderSrc = './newOrderSrc.wav'
    const newPostsSrc = './newPostsSrc.mp3'

    let postTopCategories = xiaocunPostedData ? xiaocunPostedData.postTopCategories : []
    let topPeopleAdded = keyouUserData ? keyouUserData.topuniversities : []
    let popular = []
    let peopleAddedAry = []
    let topCity = []

    //自取订单数量总和
    let totalPickUpOrders = 0

    //外卖订单数量总和
    let totalDeliveryOrders = 0

    //取消订单数量总和
    let totalInvalidOrders = 0

    //首次下单用户总数
    let totalNewUsers = 0

    //小村当前自取订单数量
    let xiaocunCurrentPickupOrders = 0

    //小村当前外卖订单数量
    let xiaocunCurrentDeliveryOrders = 0

    //小村当前取消订单
    let xiaocunInvalidOrdersNum = 0

    //小村当前新增用户
    let xiaocunNewUsers = 0

    //小村上次自取数量
    let xiaocunPrePickupOrders = 0

    //小村上次外卖数量
    let xiaocunPreDeliveryOrders = 0

    //小村上次取消数量
    let xiaocunPreInvalidOrders = 0

    //小村上次新增用户
    let xiaocunPreNewUsers = 0

    //muncho当前自取订单数量
    let munchoCurrentPickupOrders = 0

    //muncho当前外卖订单数量
    let munchoCurrentDeliveryOrders = 0

    //muncho当前取消订单
    let munchoInvalidOrdersNum = 0

    //muncho当前新增用户
    let munchoNewUsers = 0

    //muncho上次自取数量
    let munchoPrePickupOrders = 0

    //muncho上次外卖数量
    let munchoPreDeliveryOrders = 0

    //muncho上次取消数量
    let munchoPreInvalidOrders = 0

    //muncho上次新增用户
    let munchoPreNewUsers = 0

    //muncho自取订单前后对比
    let munchoPickupContrast = 0

    //小村自取订单前后对比
    let xiaocunPickupContrast = 0

    //muncho外卖订单前后对比
    let munchoDeliveryContrast = 0

    //小村外卖订单前后对比
    let xiaocunDeliveryContrast = 0

    //muncho取消订单前后对比
    let munchoInvalidContrast = 0

    //小村取消订单前后对比
    let xiaocunInvalidContrast = 0

    //muncho新增用户前后对比
    let munchoNewUsersContrast = 0

    //小村新增用户前后对比
    let xiaocunNewUsersContrast = 0

    if (xiaocunCurrentCompletedOrders && Object.keys(xiaocunCurrentCompletedOrders).length > 0) {
      totalPickUpOrders += xiaocunCurrentCompletedOrders.pickUpCount
      totalDeliveryOrders += xiaocunCurrentCompletedOrders.deliveryCount
      xiaocunCurrentPickupOrders = xiaocunCurrentCompletedOrders.pickUpCount
      xiaocunCurrentDeliveryOrders = xiaocunCurrentCompletedOrders.deliveryCount
    }
    if (munchoCurrentCompletedOrders && Object.keys(munchoCurrentCompletedOrders).length > 0) {
      totalPickUpOrders += munchoCurrentCompletedOrders.pickUpCount
      totalDeliveryOrders += munchoCurrentCompletedOrders.deliveryCount
      munchoCurrentPickupOrders = munchoCurrentCompletedOrders.pickUpCount
      munchoCurrentDeliveryOrders = munchoCurrentCompletedOrders.deliveryCount
    }
    if (munchoInvalidOrders && Object.keys(munchoInvalidOrders).length > 0) {
      totalInvalidOrders += munchoInvalidOrders.current
      munchoInvalidOrdersNum += munchoInvalidOrders.current
      munchoPreInvalidOrders = munchoInvalidOrders.previous
    }

    if (xiaocunInvalidOrders && Object.keys(xiaocunInvalidOrders).length > 0) {
      totalInvalidOrders += xiaocunInvalidOrders.current
      xiaocunInvalidOrdersNum = xiaocunInvalidOrders.current
      xiaocunPreInvalidOrders = xiaocunInvalidOrders.previous
    }

    if (xiaocunFirstTimeOrderUsers && Object.keys(xiaocunFirstTimeOrderUsers).length > 0) {
      totalNewUsers += xiaocunFirstTimeOrderUsers.current
      xiaocunNewUsers = xiaocunFirstTimeOrderUsers.current
      xiaocunPreNewUsers = xiaocunFirstTimeOrderUsers.previous
    }

    if (munchoFirstTimeOrderUsers && Object.keys(munchoFirstTimeOrderUsers).length > 0) {
      totalNewUsers += munchoFirstTimeOrderUsers.current
      munchoNewUsers = munchoFirstTimeOrderUsers.current
      munchoPreNewUsers = munchoFirstTimeOrderUsers.previous
    }

    if (munchoPreviousCompletedOrders && Object.keys(munchoPreviousCompletedOrders).length > 0) {
      munchoPreDeliveryOrders = munchoPreviousCompletedOrders.deliveryCount
      munchoPrePickupOrders = munchoPreviousCompletedOrders.pickUpCount
    }

    if (xiaocunPreviousCompletedOrders && Object.keys(xiaocunPreviousCompletedOrders).length > 0) {
      xiaocunPreDeliveryOrders = xiaocunPreviousCompletedOrders.deliveryCount
      xiaocunPrePickupOrders = xiaocunPreviousCompletedOrders.pickUpCount
    }

    munchoPickupContrast = this.calculateContrast(munchoCurrentPickupOrders, munchoPrePickupOrders)
    xiaocunPickupContrast = this.calculateContrast(xiaocunCurrentPickupOrders, xiaocunPrePickupOrders)

    munchoDeliveryContrast = this.calculateContrast(munchoCurrentDeliveryOrders, munchoPreDeliveryOrders)
    xiaocunDeliveryContrast = this.calculateContrast(xiaocunCurrentDeliveryOrders, xiaocunPreDeliveryOrders)

    munchoInvalidContrast = this.calculateContrast(munchoInvalidOrdersNum, munchoPreInvalidOrders)
    xiaocunInvalidContrast = this.calculateContrast(xiaocunInvalidOrdersNum, xiaocunPreInvalidOrders)

    munchoNewUsersContrast = this.calculateContrast(munchoNewUsers, munchoPreNewUsers)
    xiaocunNewUsersContrast = this.calculateContrast(xiaocunNewUsers, xiaocunPreNewUsers)

    if (xiaocunOrderTopCity || munchoOrderTopCity) {
      topCity = this.mergeTopCityData(xiaocunOrderTopCity ? xiaocunOrderTopCity : [], munchoOrderTopCity ? munchoOrderTopCity : [])
      topCity = topCity.slice(0, topCity.length > 5 ? 5 : topCity.length)
    }

    if (postTopCategories) {
      let popularTotal = this.countTotal(postTopCategories, 'count')
      postTopCategories.map((data) => {
        data['weight'] = Number((data.count / popularTotal).toFixed(2))
      })
      popular = postTopCategories.slice(0, postTopCategories.length > 5 ? 5 : postTopCategories.length)
    }

    if (topPeopleAdded) {
      let total = this.countTotal(topPeopleAdded, 'sum')
      topPeopleAdded.map((data) => {
        data['weight'] = Number((data.sum / total).toFixed(2))
      })
      peopleAddedAry = topPeopleAdded.slice(0, topPeopleAdded.length > 5 ? 5 : topPeopleAdded.length)
    }

    return (
      <div className="App">

        <div className="app-content">
          <audio
            src={newOrderSrc}
            ref={(newOrder) => {
              this.newOrder = newOrder;
            }}
          >
            <track src={newOrderSrc} kind="captions" />
          </audio>
          <audio
            src={newPostsSrc}
            ref={(newPosts) => {
              this.newPosts = newPosts;
            }}
          >
            <track src={newPostsSrc} kind="captions" />
          </audio>
          <audio
            src={activeUsersSrc}
            ref={(activeUsersAudio) => {
              this.activeUsersAudio = activeUsersAudio;
            }}
          >
            <track src={activeUsersSrc} kind="captions" />
          </audio>
          <div className="app-left-content">
            <div className="app-left-title">Today's Overview</div>
            <div className="app-left-date-item">
              <span ref={(year) => { this.year = year }} className="today-date">8/7/2021</span>
              <span ref={(weekDay) => { this.weekDay = weekDay }} className="today-date">Saturday</span>
              <span ref={(time) => { this.time = time }} className="today-date today-date-time">00:00:00</span>
              <span ref={(dayPeriod) => { this.dayPeriod = dayPeriod }} className="today-date">AM</span>
            </div>
            {/* Daily Revenue */}
            <div>
              <div className="title-layout">
                <img src={'./xc_delivery_logo@2x.png'} className='left-image' />
                <span className='text-fontsize-18'>Amount of Orders</span>
              </div>
              <div className='left-item-content-layout'>
                <img src={'./muncho@2x.png'} className='left-image' />
                <div>
                  <div><span className="left-num-text">{'$ '}</span><span ref={(amount) => { this.todayTotalAmount = amount }} className="left-num-text">0</span><span ref={(thousand) => { this.thousand = thousand }} className="left-num-text"></span></div>
                  <div style={{ display: 'flex', justifyContent: 'left' }}><span className="left-date-text">{'today'}</span></div>
                </div>
              </div>
            </div>
            {/* Number of Orders */}
            <div>
              <div className="title-layout">
                <img src={'./xc_delivery_logo@2x.png'} className='left-image' />
                <span className='text-fontsize-18'>Number of Orders</span>
              </div>
              <div className='left-item-content-layout'>
                <img src={'./muncho@2x.png'} className='left-image' />
                <div>
                  <div><span ref={(leftNumTv) => { this.leftNumTv = leftNumTv }} className="left-num-text">{todayNumberOfOrders}</span></div>
                  <div style={{ display: 'flex', justifyContent: 'left' }}><span className="left-date-text">{'today'}</span></div>
                </div>
              </div>
            </div>
            {/* Number of Daily Posts */}
            <div>
              <div className="title-layout">
                <img src={'./xiaocun_logo@2x.png'} className='left-image' />
                <span className='text-fontsize-18'>Number of Daily Posts</span>
              </div>
              <div className='left-item-content-layout'>
                <div className='left-image' />
                <div>
                  <div><span className="left-num-text">{todayNewPosts}</span></div>
                  <div style={{ display: 'flex', justifyContent: 'left' }}><span className="left-date-text">{'today'}</span></div>
                </div>
              </div>
            </div>
            {/* Active Users */}
            <div style={{ marginBottom: '90px' }}>
              <div className="title-layout">
                <img src={'./keyou_logo@2x.png'} className='left-image' />
                <span className='text-fontsize-18'>Active Users</span>
              </div>
              <div className='left-item-content-layout'>
                <div className='left-image' />
                <div>
                  <div><span ref={(activeUsers) => { this.activeUsers = activeUsers }} className="left-num-text">{keyouActiveUserNum}</span></div>
                  <div style={{ display: 'flex', justifyContent: 'left' }}><span className="left-date-text">{'today'}</span></div>
                </div>
              </div>
            </div>
          </div>
          {/* 左边的块 ----------------->>>>>*/}

          {/* 中间的块 ----------------->>>>>*/}
          <div className='center-content'>
            <div className='delivery-title-layout'>
              <img src={'./xc_delivery_black_icon@2x.png'} className='center-image' style={{ marginRight: '18px' }} />
              <img src={'./muncho@2x.png'} className='center-image' />
              <span className='center-title'>{'Delivery / Pickup'}</span>
            </div>

            {/* 中上的块 ----------------->>>>>*/}
            <div className='center-orders-content'>
              <div className='center-item-content'>
                <span className='center-item-title'>{'Pickup Orders'}</span>
                <span className='center-item-num'>{totalPickUpOrders}</span>
                <span className='center-item-date'>{ordersSwitchCheckWeek ? 'this week' : 'this month'}</span>
                <div className='center-item-line'></div>
                <div className='center-item-add-reduce'>
                  <span className='center-item-num-key'>{'Xiaocun' + "   " + xiaocunCurrentPickupOrders}</span>
                  <img src={xiaocunPickupContrast > 0 ? './down_logo@2x.png' : './up_logo@2x.png'} className='center-item-arrow' />
                  <span className={xiaocunPickupContrast > 0 ? 'center-item-reduce-num' : 'center-item-add-num'}>{xiaocunPickupContrast == -100 ? '--' : Math.abs(xiaocunPickupContrast) + '%'}</span>
                </div>
                <div className='center-item-add-reduce'>
                  <span className='center-item-num-key'>{'Muncho' + '   ' + munchoCurrentPickupOrders}</span>
                  <img src={munchoPickupContrast > 0 ? './down_logo@2x.png' : './up_logo@2x.png'} className='center-item-arrow' />
                  <span className={munchoPickupContrast > 0 ? 'center-item-reduce-num' : 'center-item-add-num'}>{munchoPickupContrast == -100 ? '--' : Math.abs(munchoPickupContrast) + '%'}</span>
                </div>
              </div>

              <div className='center-item-content'>
                <span className='center-item-title'>{'Delivery Orders'}</span>
                <span className='center-item-num'>{totalDeliveryOrders}</span>
                <span className='center-item-date'>{ordersSwitchCheckWeek ? 'this week' : 'this month'}</span>
                <div className='center-item-line'></div>
                <div className='center-item-add-reduce'>
                  <span className='center-item-num-key'>{'Xiaocun' + '   ' + xiaocunCurrentDeliveryOrders}</span>
                  <img src={xiaocunDeliveryContrast > 0 ? './down_logo@2x.png' : './up_logo@2x.png'} className='center-item-arrow' />
                  <span className={xiaocunDeliveryContrast > 0 ? 'center-item-reduce-num' : 'center-item-add-num'}>{xiaocunDeliveryContrast == -100 ? '--' : Math.abs(xiaocunDeliveryContrast) + '%'}</span>
                </div>
                <div className='center-item-add-reduce'>
                  <span className='center-item-num-key'>{'Muncho' + '   ' + munchoCurrentDeliveryOrders}</span>
                  <img src={munchoDeliveryContrast > 0 ? './down_logo@2x.png' : './up_logo@2x.png'} className='center-item-arrow' />
                  <span className={munchoDeliveryContrast > 0 ? 'center-item-reduce-num' : 'center-item-add-num'}>{munchoDeliveryContrast == -100 ? '--' : Math.abs(munchoDeliveryContrast) + '%'}</span>

                </div>
              </div>

              <div className='center-item-content'>
                <span className='center-item-title'>{'Invalid Orders'}</span>
                <span className='center-item-num'>{totalInvalidOrders}</span>
                <span className='center-item-date'>{ordersSwitchCheckWeek ? 'this week' : 'this month'}</span>
                <div className='center-item-line'></div>
                <div className='center-item-add-reduce'>
                  <span className='center-item-num-key'>{'Xiaocun' + '   ' + xiaocunInvalidOrdersNum}</span>
                  <img src={xiaocunInvalidContrast > 0 ? './down_logo@2x.png' : './up_logo@2x.png'} className='center-item-arrow' />
                  <span className={xiaocunInvalidContrast > 0 ? 'center-item-reduce-num' : 'center-item-add-num'}>{xiaocunInvalidContrast == -100 ? '--' : Math.abs(xiaocunInvalidContrast) + '%'}</span>

                </div>
                <div className='center-item-add-reduce'>
                  <span className='center-item-num-key'>{'Muncho' + '   ' + munchoInvalidOrdersNum}</span>
                  <img src={munchoInvalidContrast > 0 ? './down_logo@2x.png' : './up_logo@2x.png'} className='center-item-arrow' />
                  <span className={munchoInvalidContrast > 0 ? 'center-item-reduce-num' : 'center-item-add-num'}>{munchoInvalidContrast == -100 ? '--' : Math.abs(munchoInvalidContrast) + '%'}</span>

                </div>
              </div>

              <div className='center-item-content'>
                <span className='center-item-title'>{'New Users'}</span>
                <span className='center-item-num'>{totalNewUsers}</span>
                <span className='center-item-date'>{ordersSwitchCheckWeek ? 'this week' : 'this month'}</span>
                <div className='center-item-line'></div>
                <div className='center-item-add-reduce'>
                  <span className='center-item-num-key'>{'Xiaocun' + '   ' + xiaocunNewUsers}</span>
                  <img src={xiaocunNewUsersContrast > 0 ? './down_logo@2x.png' : './up_logo@2x.png'} className='center-item-arrow' />
                  <span className={xiaocunNewUsersContrast > 0 ? 'center-item-reduce-num' : 'center-item-add-num'}>{xiaocunNewUsersContrast == -100 ? '--' : Math.abs(xiaocunNewUsersContrast) + '%'}</span>
                </div>
                <div className='center-item-add-reduce'>
                  <span className='center-item-num-key'>{'Muncho' + '   ' + munchoNewUsers}</span>
                  <img src={munchoNewUsersContrast > 0 ? './down_logo@2x.png' : './up_logo@2x.png'} className='center-item-arrow' />
                  <span className={munchoNewUsersContrast > 0 ? 'center-item-reduce-num' : 'center-item-add-num'}>{munchoNewUsersContrast == -100 ? '--' : Math.abs(munchoNewUsersContrast) + '%'}</span>
                </div>
              </div>
            </div>
            {/* 中上的块 ----------------->>>>>*/}

            {/* 中中的块 ----------------->>>>>*/}
            <div className='xiaocun-posted-layout'>
              <img src={'./xiaocun_logo@2x.png'} className='center-image' />
              <span className='center-title'>{'Xiaocundayou'}</span>
            </div>

            <div className='center-posted-content'>
              <div className='center-posted-row-item'>
                <div className='center-posted-column-item'>
                  <span className='center-posted-column-title'>{'Registered Users'}</span>
                  <span className='center-posted-column-num'>{xiaocunPostedData.newUsers}</span>
                  <span className='center-posted-column-date'>{popularSwitchCheckWeek ? 'this week' : 'this month'}</span>
                </div>

                <div className='center-posted-column-item'>
                  <span className='center-posted-column-title'>{'# of People Posted'}</span>
                  <span className='center-posted-column-num'>{xiaocunPostedData.peoplePosted}</span>
                  <span className='center-posted-column-date'>{popularSwitchCheckWeek ? 'this week' : 'this month'}</span>
                </div>

                <div className='center-posted-column-item'>
                  <span className='center-posted-column-title'>{'# Number of Commented Users'}</span>
                  <span className='center-posted-column-num'>{xiaocunPostedData.peopleLeaveMessages}</span>
                  <span className='center-posted-column-date'>{popularSwitchCheckWeek ? 'this week' : 'this month'}</span>
                </div>
              </div>

              <div className='center-posted-row-item' style={{ marginTop: '35px' }}>
                <div className='center-posted-column-item'>
                  <span className='center-posted-column-title'>{'# of People Chats'}</span>
                  <span className='center-posted-column-num'>{xiaocunPostedData.peopleChats}</span>
                  <span className='center-posted-column-date'>{popularSwitchCheckWeek ? 'this week' : 'this month'}</span>
                </div>

                <div className='center-posted-column-item'>
                  <span className='center-posted-column-title'>{'New Posts'}</span>
                  <span className='center-posted-column-num'>{xiaocunPostedData.newPosts}</span>
                  <span className='center-posted-column-date'>{popularSwitchCheckWeek ? 'this week' : 'this month'}</span>
                </div>

                <div className='center-posted-column-item'>
                  <span className='center-posted-column-title'>{'Remove Posts'}</span>
                  <span className='center-posted-column-num'>{xiaocunPostedData.removePosts}</span>
                  <span className='center-posted-column-date'>{popularSwitchCheckWeek ? 'this week' : 'this month'}</span>
                </div>
              </div>
            </div>
            {/* 中中的块 ----------------->>>>>*/}
            {/* 中下的块 ----------------->>>>>*/}
            <div className='xiaocun-keyou-layout'>
              <img src={'./keyou_logo@2x.png'} className='center-image' />
              <span className='center-title'>{'Xiaocun keyou'}</span>
            </div>

            <div className='center-keyou-content'>
              <div className='center-keyou-row-item'>
                <div className='center-keyou-column-item'>
                  <span className='center-keyou-column-title'>{'New Users'}</span>
                  <span className='center-keyou-column-num' style={{ color: 'rgba(52, 142, 122, 1)' }}>{keyouUserData ? (keyouUserData.register ? keyouUserData.register : 0) : 0}</span>
                  <span className='center-keyou-column-date'>{addedSwitchCheckWeek ? 'this week' : 'this month'}</span>
                </div>

                <div className='center-keyou-column-item'>
                  <span className='center-keyou-column-title'>{'# of People Invited'}</span>
                  <span className='center-keyou-column-num' style={{ color: 'rgba(52, 142, 122, 1)' }}>{keyouUserData ? (keyouUserData.shareregister ? keyouUserData.shareregister : 0) : 0}</span>
                  <span className='center-keyou-column-date'>{addedSwitchCheckWeek ? 'this week' : 'this month'}</span>
                </div>

                <div className='center-keyou-column-item'>
                  <span className='center-keyou-column-title'>{'# of People Joining the Group Chat'}</span>
                  <span className='center-keyou-column-num' style={{ color: 'rgba(52, 142, 122, 1)' }}>{keyouUserData ? (keyouUserData.addGroup ? keyouUserData.addGroup : 0) : 0}</span>
                  <span className='center-keyou-column-date'>{addedSwitchCheckWeek ? 'this week' : 'this month'}</span>
                </div>
              </div>
            </div>
            {/* 中下的块 ----------------->>>>>*/}
          </div>
          {/* 中间的块 ----------------->>>>>*/}

          {/* 右边的块 ----------------->>>>>*/}
          <div className='right-content'>
            <div className='right-order-layout'>
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'end' }}>
                <div className='logout-layout' onClick={() => {
                  this.logout()
                }}>
                  <img src={'./icon-logout@2x.png'} className='logout-icon' />
                  <span className='logout-text'>Log out</span>
                </div>
                <div className='checkbox-layout'>
                  <div onClick={() => { this.setOrdersSwitchCheck(true) }} className={ordersSwitchCheckWeek ? 'check-button' : 'uncheck-button'} ><span>{'This Week'}</span></div>
                  <div onClick={() => { this.setOrdersSwitchCheck(false) }} className={ordersSwitchCheckWeek ? 'uncheck-button' : 'check-button'} ><span>{'This Month'}</span></div>
                </div>
              </div>
              <div className='right-orders-rank-layout'>
                <div className='right-orders-rank-row-layout'>
                  <div className='right-orders-rank-item'><span className='orders-rank-title-text'>{'Rank'}</span></div>
                  <div className='right-orders-region-item'><span className='orders-rank-title-text'>{'Region'}</span></div>
                  <div className='right-orders-xc-item'><span className='orders-rank-title-text'>{'Xiaocun Orders'}</span></div>
                  <div className='right-orders-muncho-item'><span className='orders-rank-title-text'>{'Muncho Orders'}</span></div>
                </div>
                <div style={{ height: '1px', width: '100%', backgroundColor: '#f4f4f4', marginTop: '12px' }} />
                {topCity.map((data, index) => {
                  return (<div key={index} className='right-orders-rank-row-layout'>
                    <div className='right-orders-rank-item'><span className='orders-rank-item-text'>{(index + 1)}</span></div>
                    <div className='right-orders-region-item'><span className='orders-rank-item-text'>{data.region}</span></div>
                    <div className='right-orders-xc-item'><span className='orders-rank-item-text'>{data.xiaocunOrderCount}</span></div>
                    <div className='right-orders-muncho-item'><span className='orders-rank-item-text'>{data.munchoOrderCount}</span></div>
                  </div>)
                })}
              </div>
            </div>

            <div className='right-popular-layout'>
              <div className='checkbox-layout'>
                <div onClick={() => { this.setPopularSwitchCheck(true) }} className={popularSwitchCheckWeek ? 'check-button' : 'uncheck-button'} ><span>{'This Week'}</span></div>
                <div onClick={() => { this.setPopularSwitchCheck(false) }} className={popularSwitchCheckWeek ? 'uncheck-button' : 'check-button'} ><span>{'This Month'}</span></div>
              </div>
              <div className='right-categories-layout'>
                <span className='popular-title'>{'Top5 Most Popular Categories'}</span>
                {popular ? popular.map((data, index) => {
                  return (
                    <div key={index} className='popular-row-item'>
                      <div className='most-popular-num'><span style={{ color: '#000000', alignSelf: 'flex-start' }} className='fontsize-14'>{index + 1}</span></div>
                      <div className='most-popular-name'><span style={{ color: '#000000', alignSelf: 'flex-start', textAlign: 'left' }} className='fontsize-14'>{data.name}</span></div>
                      <div className='most-popular-pieces'><span style={{ color: '#000000', alignSelf: 'flex-start' }} className='fontsize-14'>{data.count + ' pieces'}</span></div>
                      <div className={'most-popular-weight' + ' ' + `${index >= 3 ? 'weight5' : ''}`} style={{ width: data.weight * 0.7 + "rem", minWidth: '0.3rem' }}><span style={{ color: '#ffffff' }} className='fontsize-12'>{(data.weight * 100).toFixed(0) + '%'}</span></div>
                    </div>)
                }) : null}

              </div>
            </div>

            <div className='right-popular-layout'>
              <div className='checkbox-layout'>
                <div onClick={() => { this.setAddedSwitchCheck(true) }} className={addedSwitchCheckWeek ? 'check-button' : 'uncheck-button'} ><span>{'This Week'}</span></div>
                <div onClick={() => { this.setAddedSwitchCheck(false) }} className={addedSwitchCheckWeek ? 'uncheck-button' : 'check-button'} ><span>{'This Month'}</span></div>
              </div>
              <div className='right-added-layout'>
                <span className='added-title'>{'Top 5 People Added'}</span>
                {peopleAddedAry.map((data, index) => {
                  return (
                    <div key={index} className='added-row-item'>
                      <div className='added-num'><span style={{ color: '#000000', alignSelf: 'flex-start' }} className='fontsize-14'>{index + 1}</span></div>
                      <div className='added-name'><span style={{ color: '#000000', alignSelf: 'flex-start', textAlign: 'left' }} className='fontsize-14'>{data.name}</span></div>
                      <div className='added-pieces'><span style={{ color: '#000000', alignSelf: 'flex-start' }} className='fontsize-14'>{data.sum}</span></div>
                      <div className={'added-weight' + ' ' + `${index >= 3 ? 'added-weight5' : ''}`} style={{ width: data.weight * 0.7 + "rem", minWidth: '0.3rem' }}><span style={{ color: '#ffffff' }} className='fontsize-12'>{(data.weight * 100).toFixed(0) + '%'}</span></div>
                    </div>)
                })}
              </div>
            </div>
          </div>
          {/* 右边的块 ----------------->>>>>*/}
        </div>
        {this.state.showLoading ? <div className='loadingBg'>
          <ReactLoading type={'spokes'} color="#fff" />
        </div> : null}
      </div>
    );
  }
}




export default Home;
